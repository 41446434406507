const questions = {
  1: {
    title: "Как си прави бизнес срещите FYRE?",
    answers: [
      "от вкъщи с включена камера",
      "в анцуг без да си слага риза",
      "на лостове, докато тренира",
      "с бумера или с баничарката"
    ],
    correct: "в анцуг без да си слага риза",
    video: "https://www.youtube.com/watch?v=qkcX-dLmi5w"
  },
  2: {
    title: "Кога FYRE слуша гръцко?",
    answers: [
      "когато се вдъхновява за песни",
      "докато разхожда кучето",
      "докато яде кебап адана",
      "докато пътува за участие",
    ],
    correct: "докато яде кебап адана",
    video: "https://www.youtube.com/watch?v=63eUDZgltXI"
  },
  3: {
    title: "Какво те правят, ако не си \"чук\", според FYRE?",
    answers: [
      "подаряват ти цвете",
      "пращат те на фронта",
      "водят те на кафе",
      "чукат те",
    ],
    correct: "чукат те",
    video: "https://www.youtube.com/watch?v=-Nby-r7eBpM"
  },
  4: {
    title: "Какво e 02 от песните на FYRE?",
    answers: [
      "някакъв зоб",
      "eтажът, на който живее FYRE",
      "набор на 20 годишни манячета",
      "код",
    ],
    correct: "код",
    video: "https://www.youtube.com/watch?v=63eUDZgltXI"
  },
  5: {
    title: "Как иска да бъде погребан FYRE като умре?",
    answers: [
      "със специална церемония в Малашевци",
      "със златото като Тутанкамон",
      "кремация и разпръсване на праха над Фондови жилища",
      "скромно, но с много цветя",
    ],
    correct: "със златото като Тутанкамон",
    video: "https://www.youtube.com/watch?v=93P250jS6cA"
  },
  6: {
    title: "За вила къде бачка FYRE?",
    answers: [
      "на Ривиерата",
      "Младост 3",
      "Павликени",
      "Ихтиман",
    ],
    correct: "на Ривиерата",
    video: "https://www.youtube.com/watch?v=ZilAFrktjuk"
  },
  7: {
    title: "В колко часа става FYRE?",
    answers: [
      "в пет",
      "след 10:00",
      "някъде към 2 следобед",
      "има аларма за 08:00",
    ],
    correct: "в пет",
    video: "https://www.youtube.com/watch?v=HnxExIkwMFc"
  },
  8: {
    title: "Какво е правил в трафик FYRE?",
    answers: [
      "сцепил е огледалото на един",
      "слушал е аудио книга на диалект",
      "пял е песни на Лили Иванова",
      "изпреварвал е без да даде мигач",
    ],
    correct: "сцепил е огледалото на един",
    video: "https://www.youtube.com/watch?v=VEGUcekZtvI"
  },
  9: {
    title: "Как си пие кафето FYRE?",
    answers: [
      "черно",
      "с две сметанки",
      "с две супени лъжици захар",
      "само фрапе с повече мляко",
    ],
    correct: "черно",
    video: "https://www.youtube.com/watch?v=HnxExIkwMFc"
  },
  10: {
    title: "Какво става като FYRE натиска бумера?",
    answers: [
      "всичко остава зад него",
      "пада му задния мост",
      "нищо, не пали",
      "яко пуши ауспуха отзад",
    ],
    correct: "всичко остава зад него",
    video: "https://www.youtube.com/watch?v=Gd4VHqFtst8"
  },
};

export default questions;