import { useEffect, useState } from 'react';
import './App.css';
import questions from "./questions";

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}


function App() {
  const [showAnswers, setShowAnswers] = useState(true);
  const [answerClicked, clickAnswer] = useState(false);
  const [answerIsCorrect, setAnswerIsCorrect] = useState(false);
  const [questionNumber, setQuestionNumber] = useState(null);
  const [question, setQuestion] = useState(null);
  const [shuffledAnswers, setShuffledAnswers] = useState([]);

  useEffect(() => {
    const numberOfQuestions = Object.keys(questions).length + 1;
    const questionNumber = getRandomInt(1, numberOfQuestions);
    const question = questions[questionNumber];

    setQuestionNumber(questionNumber);
    setQuestion(question);

    const answers = question.answers;
    const shuffled = answers
      .map(value => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);

    setShuffledAnswers(shuffled);
    setShowAnswers(true);
    clickAnswer(false);
  }, [question])


  const makeGuess = (ev, answer) => {
    const answerEl = ev.currentTarget
    if (answerClicked) {
      return;
    }

    answerEl.className += ' clicked';
    clickAnswer(true);

    setTimeout(() => {
      const correct = answer === question.correct;
      answerEl.className += '' + (correct ? ' correct' : ' wrong');
      setAnswerIsCorrect(correct);
    }, 1000)

    setTimeout(() => {
      setShowAnswers(false);
    }, 2000)
  }

  const renderAnswer = () => {
    let message;
    if (answerIsCorrect) {
      message = "&#128591; Евала маняк, ето ти песен:";
    } else {
      message = "&#128542; Не си слушал достатъчно FYRE, чуй тука:";
    }
    const id = question.video.split("?v=")[1];
    const embedlink = "https://www.youtube.com/embed/" + id;
    const forceUpdate = () => {
      setQuestion(null);
    }

    return <div>
      <h2 dangerouslySetInnerHTML={{ __html: message }} />
      <iframe width="80%" height="180"
        title="Fyre App"
        src={embedlink}
        frameBorder="0"
        allowFullScreen></iframe>
      <a href="#" className="App-link" onClick={forceUpdate}>Искам пак 🔄</a>
    </div>
  }

  if (!question) {
    return null;
  }

  return (
    <div className="App">
      <div className="bg-pic" style={{ backgroundImage: "url('/fyre/" + questionNumber + ".jpg')" }}>
        <span className="question">{question.title}</span>
      </div>
      {showAnswers && <div className="answers">
        {shuffledAnswers.map((answer, idx) => {
          return <div
            key={idx}
            className={"answer"}
            onClick={(ev) => makeGuess(ev, answer)}
          >
            <p>&#128293; {answer}</p>
          </div>
        })}
      </div>}
      {!showAnswers && renderAnswer()}
    </div >
  );
}

export default App;
